
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import SalesProcessConfig from '@/models/interfaces/SalesProcessConfig';
import SalesProcessForm from '@/components/views/CmsIndex/SalesProcessForm.vue';
import { SalesProcessDelete } from '@/interfaces/components/cms/SalesProcessDelete';
import OrderStatusRepository from '@/repositories/OrderStatusRepository';
import LabelReporistory from '@/repositories/LabelReporistory';
import ProjectStateRepository from '@/repositories/ProjectStateRepository';
import IntroScreenItem from '@/models/IntroScreenItem';

@Component({
    name: 'SalesProcessForms',
    components: {
        SalesProcessForm,
    },
})
export default class SalesProcessForms extends Vue {
    @Prop({ default: false }) private areFieldsDisabled!: boolean;
    @Prop({ default: true }) private areFieldsVisible!: boolean;
    @Prop({ required: true }) private fieldHasErrors!: boolean;
    @Prop({ required: true }) private salesProcesses!: any[];
    @Prop({ required: true }) private entityId!: string | null;
    private pendingSalesProcesses: SalesProcessConfig[] = [];
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private maxNumberOfSalesProcesses = 6;

    private get projectStates() {
        return ProjectStateRepository.getAll();
    }

    private get orderStatuses() {
        return OrderStatusRepository.getAll();
    }

    private get labels() {
        return LabelReporistory.getAll();
    }

    private get emptySalesProcess() {
        return {
            name: '',
            color: '',
            projectState: '',
            status: '',
            id: null,
            key: this.lastPendingSalesProcessKey + 1,
        };
    }

    private get lastPendingSalesProcessKey() {
        if (this.pendingSalesProcesses.length <= 0) {
            return 0;
        }
        return this.pendingSalesProcesses.slice(-1)[0].key;
    }

    private get isDeleteFieldVisible() {
        return this.pendingSalesProcesses.length > 1;
    }

    private async addEmptyPendingSalesProcess() {
        try {
            await this.validateMaxNumberOfSalesProcesses();
        } catch (error) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (error as Error).message,
            });
            return;
        }
        this.pendingSalesProcesses.push(this.emptySalesProcess);
        this.scrollToPendingSaleProcess();
    }

    private scrollToPendingSaleProcess() {
        this.$nextTick(() => {
            const lastPendingSalesProcessElement = this.$refs[
                `pendingSalesProcess-${this.lastPendingSalesProcessKey}`
            ] as Vue[];

            lastPendingSalesProcessElement[0].$el.scrollIntoView({ behavior: 'smooth' });
            (lastPendingSalesProcessElement[0].$refs.name as HTMLElement).focus();
        });
    }

    private deleteSalesProcess(salesProcessesDeleteSettings: SalesProcessDelete) {
        const filteredSalesProcesses = this.pendingSalesProcesses.filter(
            (pendingSalesProcess) => pendingSalesProcess.name !== ''
        );
        if (salesProcessesDeleteSettings.salesProcess.id != null && filteredSalesProcesses.length <= 1) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: this.$t('Ne možete obrisati jedini postojeći prodajni proces') as string,
            });
            return;
        }

        this.$confirm({
            title: this.$t('Jeste li sigurni da želite obrisati ovaj prodajni proces?') as string,
            content: '',
            okText: this.$t('Da') as string,
            okType: 'danger',
            cancelText: this.$t('Ne') as string,
            class: 'c-delete-modal',
            onOk: () => {
                this.deleteSalesProcessAndClosePopup(salesProcessesDeleteSettings);
            },
        });
    }

    private async deleteSalesProcessAndClosePopup(salesProcessesDeleteSettings: SalesProcessDelete) {
        try {
            await this.deleteSalesProcessRemoteAndLocal(salesProcessesDeleteSettings);
        } catch (error) {
            this.loadingOverlay.stop();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (error as Error).message,
            });
            return;
        }
        EventBus.$emit(EventBusEvents.closePopup);
        this.$notification.success({
            message: this.$t('Prodajni proces uspješno obrisan!') as string,
            description: '',
        });
        this.loadingOverlay.stop();
    }

    private async deleteSalesProcessRemoteAndLocal({
        pendingSalesProcessIndex,
        salesProcess,
        newSalesProcessId,
    }: SalesProcessDelete) {
        this.removeSalesProcessFromArray(pendingSalesProcessIndex);
        this.loadingOverlay.start();
        if (salesProcess.id == null) {
            this.loadingOverlay.stop();
            return;
        }

        try {
            await IntroScreenItem.deleteExisting(salesProcess.id);
        } catch (e) {
            return Promise.reject(e);
        }
        return Promise.resolve();
    }

    private removeSalesProcessFromArray(pendingSalesProcessKey: number) {
        const pendingSalesProcessIndex = this.pendingSalesProcesses.findIndex(
            (pendingSalesProcess: SalesProcessConfig) => {
                return pendingSalesProcess.key === pendingSalesProcessKey;
            }
        );
        this.pendingSalesProcesses.splice(pendingSalesProcessIndex, 1);
    }

    private async validateAndSubmitForms() {
        let salesProcessIds = [];
        try {
            await this.areSalesProcessesFormsValid();
            salesProcessIds = await this.createSalesProcesses();
        } catch (error) {
            throw error;
        }

        return Promise.resolve(salesProcessIds);
    }

    private async areSalesProcessesFormsValid() {
        return await Promise.all(
            Object.values(this.$refs)
                .filter((salesProcess) => (salesProcess as Vue[]).length > 0)
                .map(async (salesProcess) => {
                    let isFormValid = false;

                    try {
                        // @ts-ignore
                        isFormValid = await salesProcess[0].validateForm();
                    } catch (error) {
                        throw error;
                    }
                    return isFormValid;
                })
        );
    }

    private validateMaxNumberOfSalesProcesses() {
        if (this.pendingSalesProcesses.length >= this.maxNumberOfSalesProcesses) {
            throw new Error(
                (this.$t('Ne smijete imati više od __MAXNUMBER__ prodajnih procesa') as string).replace(
                    '__MAXNUMBER__',
                    `${this.maxNumberOfSalesProcesses}`
                )
            );
        }
        return Promise.resolve();
    }

    private async createSalesProcesses() {
        return await Promise.all(
            Object.values(this.$refs)
                .filter((salesProcess) => (salesProcess as Vue[]).length > 0)
                .map(async (salesProcess) => {
                    let id = null;
                    try {
                        // @ts-ignore
                        id = await salesProcess[0].handleSubmit();
                    } catch (error) {
                        this.$notification.error({
                            message: this.$t('Dogodila se greška') as string,
                            description: (error as Error).message,
                        });
                    }
                    return id;
                })
        );
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }

    @Watch('salesProcesses', { immediate: true, deep: true })
    private onSalesProcessesChange() {
        if (this.salesProcesses.length <= 0) {
            this.addEmptyPendingSalesProcess();
            return;
        }

        this.pendingSalesProcesses = [];
        const sortedSalesProcesses = this.salesProcesses.slice().sort((a, b) => {
            return a.rowNumber - b.rowNumber;
        });

        sortedSalesProcesses.forEach((salesProcess: any) => {
            this.pendingSalesProcesses.push({
                ...salesProcess,
                key: this.lastPendingSalesProcessKey + 1,
            });
        });
    }
}
