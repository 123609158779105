
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import Country from '@/models/Country';
import CountryRepository from '@/repositories/CountryRepository';
import { Button, Divider, Form, Input, Select } from 'ant-design-vue';
import Textarea from 'primevue/textarea/Textarea';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import UserAliasRepository from '@/repositories/UserAliasRepository';
import { validateAndSubmitUserAlias } from '@/helpers/CmsIndex/UserAliasHelper';
import UserAliasConfig from '@/interfaces/components/cms/UserAliasConfig';
import { AxiosError } from 'axios';

@Component({
    name: 'UserAliasForm',
    components: { Form, FormItem: Form.Item, Select, SelectOption: Select.Option, Textarea, Input, Divider, Button },
})
export default class UserAliasForm extends Vue {
    @Prop({ default: null }) private userAliasId!: string | null;

    private get form() {
        return this.$form.createForm(this, {
            name: 'userAliasForm',
        } as IformCreateOption);
    }

    private get countries() {
        return CountryRepository.getAll() || [];
    }

    private get userAlias() {
        return UserAliasRepository.getById(this.userAliasId);
    }

    public async validateAndSubmit(event?: Event): Promise<UserAliasConfig> {
        if (event) {
            event.preventDefault();
        }
        let values;

        try {
            values = await validateAndSubmitUserAlias({
                form: this.form,
                userAliasId: this.userAliasId,
            });
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject(e);
        }

        return Promise.resolve(values);
    }

    private mounted() {
        Country.getAll();
    }
}
