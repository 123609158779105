import PostCodeConnection from '@/models/interfaces/PostCodeConnection';
import i18n from '@/i18n';
import { EntitySegment } from '@/interfaces/components/cms/EntitySegment';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { FieldTypes } from '@/enums/components/CmsIndex/FieldTypes';

export function validateNumberRange(numberRangeGroup: PostCodeConnection) {
    let hasErrors = false;
    let message = '';
    if (numberRangeGroup.country === '') {
        hasErrors = true;
        message = i18n.t('Poštanski broj mora imati dodijeljenu državu!') as string;
    }
    if (numberRangeGroup.toNumber == null || numberRangeGroup.fromNumber == null) {
        hasErrors = true;
        message = i18n.t('Poštanski brojevi moraju imati i početni i krajnji broj!') as string;
    }

    if (numberRangeGroup.toNumber < numberRangeGroup.fromNumber) {
        hasErrors = true;
        message = i18n.t('Neki od navedenih poštanskih brojeva ima veći početni broj od krajnjeg!') as string;
    }

    if (numberRangeGroup.toNumber === numberRangeGroup.fromNumber) {
        hasErrors = true;
        message = i18n.t('Poštanski brojevi ne mogu započeti i završiti s istim brojem!') as string;
    }
    return {
        hasErrors,
        message,
    };
}

export function getNumberRangeGroups(entitySegments: EntitySegment[], entityInfo: any) {
    const editableProperties = entitySegments.map((segment: EntitySegment) =>
        segment.editablePropertiesConfiguration).flat();

    const extendedFilesEditableProperties = editableProperties.filter((editableProperty: EditableProperty) => {
        return editableProperty.type === FieldTypes.NumberRangeGroup;
    });
    return extendedFilesEditableProperties.map((editableProperty: EditableProperty) => {
        return entityInfo[editableProperty.name];
    });
}
