
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';

@Component({
    name: 'MultipleImagesWrapper',
})
export default class MultipleImagesWrapper extends Vue {
    @Prop({required: true}) private fileList!: any[];
    @Prop({required: true}) private isDisabled!: boolean;
    @Prop({required: true}) private editableProperty!: EditableProperty;
    @Prop({required: true}) private customRequest!:
        ({file, onSuccess, onError}: any, {
            editablePropertyName,
            fileType,
            nestedPropertyIndex,
            nestedPropertyName,
            imageType,
        }: {
            editablePropertyName: string;
            fileType: string;
            nestedPropertyIndex?: number;
            nestedPropertyName?: string;
            imageType?: string;
        }) => void;
    @Prop({required: true}) private onRemove!:
        (file: any, editablePropertyName: string, arrayIndex: number) => void;
}
