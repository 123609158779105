
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserAlias from '@/models/UserAlias';
import { Button, Icon, Popconfirm } from 'ant-design-vue';

@Component({
    name: 'UserAliasItem',
    components: {
        Button,
        Icon,
        Popconfirm,
    },
})
export default class UserAliasItem extends Vue {
    @Prop({required: true}) private alias!: UserAlias;

    private get phoneNumber() {
        return this.alias.phoneNumber && this.alias.phoneNumber !== '' ? this.alias.phoneNumber : '-';
    }

    private get email() {
        return this.alias.email && this.alias.email !== '' ? this.alias.email : '-';
    }

    private onEdit() {
        this.$emit('edit', this.alias.id);
    }

    private onDelete() {
        this.$emit('delete', this.alias.id);
    }
}
