
import RadioButtonGroup from '@/components/global/RadioButtonGroup.vue';
import SearchMaterials from '@/components/views/project/SearchMaterials.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MultipositionPopupSettings } from '@/interfaces/components/configurator/MultipositionPopupSettings';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';
import ProductCategory from '@/models/ProductCategory';
import ProductAndSystemSelector from '@/components/global/category-selector/ProductAndSystemSelector.vue';
import { generateProductCategoryOptions } from '@/helpers/Products/ProductHelper';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';


@Component({
    name: 'CategorySelector',
    components: {ProductAndSystemSelector, RadioButtonGroup, SearchMaterials},
})
export default class CategorySelector extends Vue {
    @Prop({default: null}) private activeProjectId!: string;
    @Prop({default: null}) private selectedOfferId!: string;
    @Prop({default: null}) private clientsPaymentTypeId!: string | null;
    @Prop({default: true}) private shouldShowMaterials!: boolean;
    @Prop({default: true}) private detailedSystemView!: boolean;
    @Prop({default: () => []}) private offerItems!: Array<OfferItem | OfferTitle>;
    @Prop({required: true}) private productCategoryId!: string;
    @Prop({required: true}) private productCategories!: ProductCategory[];

    @Prop({
        default: () => () => {
            return null;
        },
    }) private onProductClick!: (productSettings: any) => void;
    @Prop({required: true}) private radioButtonChange!: (value: string) => void;
    // @ts-ignore
    @Prop({
        default: () => () => {
            return null;
        },
    }) private openPopup!: (multipositionPopupSettings: MultipositionPopupSettings) => void;
    @Prop({required: true}) private isCategoryMaterials!: () => boolean;
    @Prop({required: true}) private shouldPickerBeLocked!: boolean;
    @Prop({required: true}) private shouldHaveThreeLevelsOfNavigation!: boolean;
    @Prop({default: null }) private onProductFormClick!: (productId: string) => void | null;

    public get productsByCategoryId() {
        if (this.productCategoryId === null) {
            return [];
        }

        const categoryById = ProductCategoriesRepository.getById(this.productCategoryId);
        if (categoryById == null) {
            return [];
        }

        return categoryById.products;
    }

    public get productCategoryOptions(): RadioButtonGroupOptions[] {
        return generateProductCategoryOptions(this.productCategories, this.shouldShowMaterials);
    }
    private get defaultGroup() {
        return this.productCategoryId ?? this.productCategoryOptions[0].value;
    }
}
