
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IformCreateOption } from 'ant-design-vue/types/form/form';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Chrome } from 'vue-color';
import ProjectState from '@/models/ProjectState';
import Label from '@/models/Label';
import OrderStatus from '@/models/OrderStatus';
import { SalesProcessDelete } from '@/interfaces/components/cms/SalesProcessDelete';
import OrderStatusRepository from '@/repositories/OrderStatusRepository';
import LabelReporistory from '@/repositories/LabelReporistory';
import IntroScreenItem from '@/models/IntroScreenItem';

@Component({
    name: 'SalesProcessForm',
    components: {
        'chrome-picker': Chrome,
    },
})
export default class SalesProcessForm extends Vue {
    @Prop({ default: false }) private areFieldsDisabled!: boolean;
    @Prop({ default: true }) private areFieldsVisible!: boolean;
    @Prop({ default: false }) private isDeleteFieldVisible!: boolean;
    @Prop({ required: false }) private fieldHasErrors!: boolean;
    @Prop({ required: true }) private salesProcess!: any;
    @Prop({ required: true }) private id!: number;
    @Prop({ required: true }) private itemKey!: number;
    @Prop({ required: true }) private onDelete!: (salesProcessDeleteOption: SalesProcessDelete) => void;
    @Prop({ required: true }) private projectStates!: ProjectState[];
    @Prop({ required: true }) private orderStatuses!: OrderStatus[];
    @Prop({ required: true }) private labels!: Label[];
    @Prop({ required: true }) private entityId!: string | null;

    private color = this.salesProcess.color ? this.salesProcess.color : { hex: '#FF0000' };
    private selectedProjectId: null | string = this.salesProcess.state ? this.salesProcess.state : null;

    private get initialStatusValue() {
        if (
            this.salesProcess.state == null ||
            this.salesProcess.state === '' ||
            this.salesProcess.state === 'request'
        ) {
            return '';
        }

        if (this.salesProcess.state === 'order') {
            return this.salesProcess.orderStatus.id;
        }

        return this.salesProcess.projectLabel.id;
    }

    private get statuses() {
        if (this.selectedProjectId == null) {
            return [];
        }
        if (this.selectedProjectId === 'order') {
            return OrderStatusRepository.getAll();
        }

        return LabelReporistory.getLabelsByProjectState(this.selectedProjectId);
    }

    private get label() {
        const labelId = this.id + 1;
        return `${this.$t('Prodajni proces')} ${labelId}`;
    }

    private get form() {
        return this.$form.createForm(this, { name: `salesProcess-${this.itemKey}` } as IformCreateOption);
    }

    private get isSalesProcessExisting() {
        return this.salesProcess.id != null;
    }

    public validateForm() {
        return new Promise<void>((resolve, reject) => {
            // @ts-ignore
            this.form.validateFieldsAndScroll(async (err: Error) => {
                if (!err) {
                    return resolve();
                }
                return reject(new Error(this.$t('Postoje greške u formi') as string));
            });
        });
    }

    private onInput(input: string) {
        this.emitDataChanges(true);
    }

    private handleSubmit() {
        return new Promise((resolve, reject) => {
            if (this.entityId == null) {
                return reject(new Error('Entity ID is not defined'));
            }
            // @ts-ignore
            this.form.validateFieldsAndScroll(async (err: Error, values: SalesProcessConfig) => {
                if (!err) {
                    let salesProcess;
                    const introScreenItemInfo = {
                        ...values,
                        rowNumber: this.id + 1,
                        color: this.color,
                        introScreen: this.entityId,
                    };

                    try {
                        if (!this.isSalesProcessExisting) {
                            salesProcess = await IntroScreenItem.createNew(introScreenItemInfo);
                        } else {
                            salesProcess = await IntroScreenItem.updateExisting(
                                this.salesProcess.id,
                                introScreenItemInfo
                            );
                        }
                    } catch (e) {
                        return reject(e);
                    }
                    return resolve(salesProcess.data.id);
                }
                return reject(new Error(this.$t('Postoje greške u formi') as string));
            });
        });
    }

    private onSelectProjectState(projectStateId: string) {
        if (projectStateId === this.selectedProjectId) {
            return;
        }

        this.selectedProjectId = projectStateId;
        this.form.setFieldsValue({
            status: '',
        });
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }
}
