var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.areFieldsVisible),expression:"areFieldsVisible"}],staticClass:"c-sales-process-form",attrs:{"form":_vm.form}},[_c('div',{staticClass:"c-sales-process-form__label"},[_c('span',{staticClass:"u-a1"},[_vm._v(_vm._s(_vm.label))]),_c('AButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDeleteFieldVisible),expression:"isDeleteFieldVisible"}],staticClass:"c-sales-process-form__delete",attrs:{"type":"link"},on:{"click":function($event){return _vm.onDelete({
                    pendingSalesProcessIndex: _vm.itemKey,
                    salesProcess: _vm.salesProcess,
                })}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1),_c('AFormItem',{staticClass:"c-sales-process-form__item u-b1",attrs:{"label":_vm.$t('Ime')}},[_c('AInput',{directives:[{name:"debounce",rawName:"v-debounce:800ms.change",value:(_vm.onInput),expression:"onInput",arg:"800ms",modifiers:{"change":true}},{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                    initialValue: _vm.salesProcess ? _vm.salesProcess.name : '',
                    rules: [
                        { required: true, message: _vm.$t('Naziv je obavezan!'), whitespace: true },
                        { max: 20, message: _vm.$t('Naziv ne može imati više od 20 znakova!'), whitespace: true },
                    ],
                },
            ]),expression:"[\n                'name',\n                {\n                    initialValue: salesProcess ? salesProcess.name : '',\n                    rules: [\n                        { required: true, message: $t('Naziv je obavezan!'), whitespace: true },\n                        { max: 20, message: $t('Naziv ne može imati više od 20 znakova!'), whitespace: true },\n                    ],\n                },\n            ]"}],ref:"name",attrs:{"data-test":"name","disabled":_vm.areFieldsDisabled}})],1),_c('AFormItem',{staticClass:"c-sales-process-form__item u-b1",attrs:{"label":_vm.$t('Boja')}},[_c('chrome-picker',{attrs:{"disableAlpha":true},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1),_c('AFormItem',{staticClass:"c-sales-process-form__item u-b1",attrs:{"label":_vm.$t('Status projekta')}},[_c('ASelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'projectState',
                {
                    initialValue: _vm.salesProcess.state,
                    rules: [{ required: true, message: _vm.$t('Status projekta je obavezan!') }],
                },
            ]),expression:"[\n                'projectState',\n                {\n                    initialValue: salesProcess.state,\n                    rules: [{ required: true, message: $t('Status projekta je obavezan!') }],\n                },\n            ]"}],attrs:{"disabled":_vm.areFieldsDisabled,"show-search":"","filterOption":"","optionFilterProp":"children","placeholder":_vm.$t('Odaberite')},on:{"select":(projectStateId) => {
                    _vm.onSelectProjectState(projectStateId);
                }}},_vm._l((_vm.projectStates),function(projectState){return _c('ASelectOption',{key:projectState.id,staticClass:"u-b1",attrs:{"value":projectState.id}},[_vm._v(" "+_vm._s(projectState.label)+" ")])}),1)],1),_c('AFormItem',{staticClass:"c-sales-process-form__item u-b1",attrs:{"label":_vm.$t('Status')}},[_c('ASelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'status',
                {
                    initialValue: _vm.initialStatusValue,
                    rules: [{ required: _vm.statuses.length > 0, message: _vm.$t('Podstatus je obavezan!') }],
                },
            ]),expression:"[\n                'status',\n                {\n                    initialValue: initialStatusValue,\n                    rules: [{ required: statuses.length > 0, message: $t('Podstatus je obavezan!') }],\n                },\n            ]"}],attrs:{"disabled":_vm.areFieldsDisabled || _vm.statuses.length <= 0,"show-search":"","filterOption":"","allowClear":"","optionFilterProp":"children","placeholder":_vm.$t('Odaberite')}},_vm._l((_vm.statuses),function(status){return _c('ASelectOption',{key:status.id,staticClass:"u-b1",attrs:{"value":status.id}},[_vm._v(" "+_vm._s(status.name)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }