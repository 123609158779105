
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { RadioButtonGroupOptions } from '@/interfaces/components/RadioButtonGroupOptions';

@Component({
    name: 'RadioButtonGroup',
})
export default class RadioButtonGroup extends Vue {
    @Prop({ default: false }) private readonly isSolid!: boolean;
    @Prop({ default: '' }) private readonly defaultValue!: string;
    @Prop({ default: false }) private hasGridLayout!: boolean;
    @Prop({ default: null }) private topButtonColSpan!: number | null;
    @Prop({ required: true }) private readonly options!: RadioButtonGroupOptions[];

    private buttonWidth = 150;
    private radioValue: string = this.defaultValue;

    private get buttonStyle() {
        return this.isSolid ? 'solid' : 'outline';
    }

    // private get radioButtonStyle() {
    //     return {
    //         maxWidth: this.hasGridLayout ? `${this.buttonWidth * (this.options.length - 1)}px` : undefined,
    //         '--button-width': `${this.buttonWidth}px`,
    //     };
    // }

    private get itemWidth() {
        if (!this.hasGridLayout) {
            return undefined;
        }

        return this.topButtonColSpan != null ? `${this.buttonWidth * this.topButtonColSpan}px` : '100%';
    }

    private onChange(e: Event) {
        /*const eventTarget = e.target as HTMLInputElement | null;

        if (eventTarget === null) {
            return;
        }*/

        this.$emit('radioButtonChange', e);
    }

    @Watch('defaultValue')
    private defaultValueChanged() {
        this.radioValue = this.defaultValue;
        this.$forceUpdate();
    }
}
