
import Hero1Icon from '@/components/icons/Hero1Icon.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Product from '@/models/Product';
import { Button, Popover } from 'ant-design-vue';
import {
    determineIfProductCategoryMustBeSimple,
    transformProducts,
    transformProductSystems,
} from '@/helpers/Products/ProductHelper';

@Component({
    name: 'TopLevelProductSelector',
    components: {Hero1Icon, Popover, Button},
})

export default class TopLevelProductSelector extends Vue {
    @Prop({required: true}) private shouldPickerBeLocked!: boolean;
    @Prop({required: true}) private detailedSystemView!: boolean;
    @Prop({required: true}) private productsByCategoryId!: Product[];
    @Prop({required: true}) private shouldHaveThreeLevelsOfNavigation!: boolean;
    @Prop({required: true}) private productCategoryId!: string;
    @Prop({required: true}) private selectedProductId!: string;

    private get itemsToDisplay() {
        if (this.detailedSystemView &&
            determineIfProductCategoryMustBeSimple(this.productCategoryId)) {
            return transformProductSystems(this.productSystems);
        }

        return transformProducts(this.productsByCategoryId);
    }

    private get productSystems() {
        return this.productsByCategoryId.map((product) => {
            return product.productSystems;
        }).flat();
    }

    private onSelectProduct(productId: string) {
        this.$emit('update:selectedProductId', productId);
        this.$emit('selectedProductIdChanged', productId);
    }

    private onProductClick(item: any) {
        this.$emit('onProductClick', {
            productFormId: item.productFormId,
            productId: item.productId,
            productSystemName: item.isProductCustom ? null : item.productSystemName,
            productSystemCode: item.isProductCustom ? null : item.productSystemCode,
            productName: item.productName,
        });
    }

    private openMultiplierPopup(item: any, multipositionType: string) {
        this.$emit('openMultiplierPopup', {
            productFormId: item.productFormId,
            productId: item.productId,
            productSystemName: item.isProductCustom ? null : item.productSystemCode,
            productName: item.productName,
            productSystemId: item.productSystemId,
            multipositionType,
        });
    }
}
