import { UserAliasSubmitOptions } from '@/interfaces/components/cms/UserAliasSubmitOptions';
import i18n from '@/i18n';
import UserAliasConfig from '@/interfaces/components/cms/UserAliasConfig';
import UserAlias from '@/models/UserAlias';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import UserAliasRepository from '@/repositories/UserAliasRepository';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { AxiosError } from 'axios';

/**
 * Runs the validation on the given form entity from Antd design
 * Returns the form values if everything is fine
 * @param options - All options necessary to create a user alias entity
 * @return A promise
 */
export async function validateAndSubmitUserAlias(options: UserAliasSubmitOptions): Promise<UserAliasConfig> {
    return new Promise((resolve, reject) => {
        if (options.form == null) {
            return reject(new Error('A form component was not provided'));
        }

        options.form.validateFieldsAndScroll(async (err: Error[], values: UserAliasConfig) => {
            try {
                validateUserAliasForm(err, values.country, options.userAliasId, options.form as WrappedFormUtils);
            } catch (e) {
                return reject(e);
            }

            return resolve(values);
        });
    });
}

/**
 * Throws an error if there are errors in the form
 * @param errors - An array of errors from the form
 * @param userAliasId - The currently active user alias id (if editing)
 * @param countryId - The used country id
 * @param form - The reference to the active form
 * @return A promise
 */
export function validateUserAliasForm(
    errors: Error[],
    countryId: string,
    userAliasId: string | null | undefined,
    form: WrappedFormUtils
) {
    const existingUserAliases = UserAliasRepository.getAll();
    const usedCountryIds = existingUserAliases
        .filter((userAlias) => userAlias.id !== userAliasId)
        .map((userAlias) => userAlias.country.id);

    if (usedCountryIds.includes(countryId)) {
        form.setFields({
            country: {
                value: countryId,
                errors: [
                    // @ts-ignore
                    { message: i18n.t('Ova država je već iskorištena'), field: 'country' },
                ],
            },
        });
        throw new Error(i18n.t('Postoje greške u formi'));
    }

    if (errors) {
        throw new Error(i18n.t('Postoje greške u formi'));
    }
}

/**
 * Sends an API request with the correct payload for creating or updating the user alias entity
 * The creation or updating is based on the content of the given user alias id
 * @param userAlias - The UserAlias entity that needs to be sent to backend
 * @param userId - The user id on which the user alias has to be created
 * @return A promise with the updated/created user alias entity
 */
export async function createOrUpdateUserAlias(userAlias: UserAlias, userId: string) {
    let newUserAlias;
    const shouldCreateNewUserAlias = userAlias.id.includes('uid');

    const payload = {
        ...userAlias,
        user: userId,
    };

    try {
        if (shouldCreateNewUserAlias) {
            newUserAlias = await UserAlias.createNew(payload);
        } else {
            newUserAlias = await UserAlias.updateExisting(userAlias.id, payload);
        }
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve(newUserAlias);
}

/**
 * Checks that the user is in the correct CMS entity and iterates through all given user aliases
 * to be created or updated on the backend
 * @param userAliases - All UserAlias entities that need to be sent to backend
 * @param userId - The user id on which the user alias has to be created
 * @param cmsEntityName - The current CMS entity name
 * @return A promise
 */
export async function updateOrCreateUserAliases(userAliases: UserAlias[], userId: string, cmsEntityName: string) {
    if (cmsEntityName !== CmsEntityTypes.Users) {
        return Promise.resolve();
    }

    for await (const userAlias of userAliases) {
        try {
            await createOrUpdateUserAlias(userAlias, userId);
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return Promise.reject();
        }
    }

    return Promise.resolve();
}

/**
 * Deletes all UserAlias entities if the current entity name is Users
 * @param cmsEntityName - The current CMS entity name
 * @return A promise
 */
export async function clearAllLocalUserAliasesIfOnUsersCMS(cmsEntityName: CmsEntityTypes) {
    if (cmsEntityName === CmsEntityTypes.Users) {
        await UserAlias.deleteAll();
    }

    return Promise.resolve();
}

/**
 * Creates an ORM entry based on the given values
 * @param editUserAliasId - The user alias id that is being edited (if exists)
 * @param values - All values necessary to create a user alias
 * @return A promise
 */
export async function insertOrUpdateUserAlias(editUserAliasId: string | null, values: UserAliasConfig) {
    await UserAlias.insertOrUpdate({
        data: {
            id: editUserAliasId ? editUserAliasId : undefined,
            phoneNumber: (values as UserAliasConfig).phoneNumber,
            email: (values as UserAliasConfig).email,
            country: {
                id: (values as UserAliasConfig).country,
            },
        },
    });

    return Promise.resolve();
}
