import PaymentType from '@/models/PaymentType';
import Client from '@/models/Client';
import { PaymentTypeRelatedEntities } from '@/interfaces/components/cms/PaymentTypeRelatedEntities';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';

async function fetchDefaultPaymentAndRelatedEntities(paymentTypeId: string) {
    let clientsByPaymentType = [];

    try {
        await PaymentType.fetchDefaultPaymentType();
        clientsByPaymentType = await Client.fetchByPaymentType(paymentTypeId);
    } catch (error) {
        throw error;
    }

    return Promise.resolve({
        clientsByPaymentType,
    });
}

async function updatePaymentTypesOnRelatedEntities(
    relatedEntities: PaymentTypeRelatedEntities,
    defaultPaymentType: PaymentType,
) {
    await Promise.all(relatedEntities.clientsByPaymentType.map((client: Client) => {
        return Client.updatePaymentType(client.id, {
            paymentType: defaultPaymentType.id,
        });
    }));

    return Promise.resolve();
}

export async function updateClientToDefaultPaymentTypeAndArchiveSelectedPaymentType(entityId: string) {
    let relatedEntities: null | PaymentTypeRelatedEntities = null;

    try {
        relatedEntities = await fetchDefaultPaymentAndRelatedEntities(entityId);
    } catch (e) {
        throw e;
    }
    const defaultPaymentType = PaymentTypeRepository.getDefault();

    if (defaultPaymentType == null) {
        throw new Error('Default payment type does not exist');
    }

    try {
        await updatePaymentTypesOnRelatedEntities(relatedEntities, defaultPaymentType);
        await archivePaymentType(entityId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}


async function archivePaymentType(paymentTypeId: string) {
    try {
        await PaymentType.archiveExisting(paymentTypeId);
    } catch (e) {
        throw e;
    }

    return Promise.resolve();
}
