
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { EntityInfo } from '@/interfaces/components/cms/EntityInfo';

@Component({
    name: 'MultipleTextareas',
})
export default class MultipleTextareas extends Vue {
    @Prop({required: true}) private editableProperty!: EditableProperty;
    @Prop({required: true}) private entityInfo!: EntityInfo;
}
