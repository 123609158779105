
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserAlias from '@/models/UserAlias';
import UserAliasItem from '@/components/views/CmsIndex/UserAliasItem.vue';
import { Button, Icon, Modal } from 'ant-design-vue';
import UserAliasForm from '@/components/views/CmsIndex/UserAliasForm.vue';
import { ModalWidth } from '@/enums/global/ModalWidth';
import UserAliasRepository from '@/repositories/UserAliasRepository';
import { insertOrUpdateUserAlias } from '@/helpers/CmsIndex/UserAliasHelper';

@Component({
    name: 'UserAliases',
    components: {
        UserAliasForm,
        UserAliasItem,
        Button,
        Icon,
        Modal,
    },
})
export default class UserAliases extends Vue {
    @Prop({default: () => []}) private aliases!: UserAlias[];

    private modalWidth = ModalWidth;
    private isModalVisible = false;
    private editUserAliasId: null | string = null;
    private isLoading = false;

    private closeModal() {
        this.isModalVisible = false;
        this.editUserAliasId = null;
    }

    private openModal(editUserAliasId?: string | PointerEvent) {
        if (typeof editUserAliasId === 'string') {
            this.editUserAliasId = editUserAliasId;
        }
        this.isModalVisible = true;
    }

    private async onModalConfirm() {

        this.isLoading = true;

        let values;

        try {
            values = await (this.$refs.userAliasForm as UserAliasForm).validateAndSubmit();
        } catch (e) {
            return;
        } finally {
            this.isLoading = false;
        }

        await insertOrUpdateUserAlias(this.editUserAliasId, values);
        this.$emit('updateEntityInfo', UserAliasRepository.getAll());
        this.closeModal();
    }

    private async onDelete(id: string) {
        await UserAlias.deleteExisting(id);
        this.$emit('updateEntityInfo', UserAliasRepository.getAll());
    }

}
