
import { Component, Vue, Watch } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import CmsIndexDetails from '@/components/views/CmsIndex/CmsIndexDetails.vue';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { getDefaultValue, setEntityDefaultConfig } from '@/helpers/CmsIndexHelper';
import CmsSaveIndexButton from '@/components/views/CmsIndex/CmsIndexSaveButton.vue';
import { EntityConfiguration } from '@/interfaces/components/cms/EntityConfiguration';
import { AxiosResponse } from 'axios';
import { EntityInfo } from '@/interfaces/components/cms/EntityInfo';
import { EntitySegment } from '@/interfaces/components/cms/EntitySegment';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import UserGroupConnection from '@/models/UserGroupConnection';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import ProductCategoriesRepository from '@/repositories/ProductCategoriesRepository';
import ProductCategory from '@/models/ProductCategory';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import CategorySelector from '@/components/global/category-selector/CategorySelector.vue';
import OrderStatus from '@/models/OrderStatus';
import ProjectState from '@/models/ProjectState';
import Label from '@/models/Label';
import LocalePicker from '@/components/global/LocalePicker.vue';
import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';
import UploadedPdf from '@/models/UploadedPdf';
import UserAlias from '@/models/UserAlias';
import SettingsTopBar from '@/components/views/settings/SettingsTopBar.vue';
import { ProductRepository } from '@/repositories/ProductRepository';
@Component({
    name: 'CmsIndex',
    components: {
        SettingsTopBar,
        CmsSaveIndexButton,
        CmsIndexDetails,
        CategorySelector,
        LocalePicker,
    },
    mixins: [CheckUnsavedDataMixin],
})
export default class CmsIndex extends Vue {
    private entityId: string | null = null;
    private additionalEntityId: string | null = null;
    private isEditing: boolean = false;
    private entityConfig: EntityConfiguration | null = null;
    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private entityFormValues: EntityInfo = {};
    private cmsEntityTypes = CmsEntityTypes;
    private hasUnsavedData = false;
    private productCategoryId: string | null = null;
    private selectedLocale: string | null = this.currentLocale;
    private initialFormValues: any[] = [];
    public get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }

    private async mounted() {
        EventBus.$on(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
        try {
            await this.fetchAllRecords(ProductCategory);
        } catch (e) {
            return;
        }

        this.setInitialProductCategory();
        this.setLocalFormValuesObject();
    }

    private get currentLocale() {
        if (this.$i18n == null) {
            return 'sl';
        }
        return this.$i18n.locale;
    }
    private get shouldShowCmsForms() {
        if (this.entityConfig == null) {
            return true;
        }
        return !(this.isStartPageActive && this.entityConfig.shouldHandleStartPage);
    }
    private get isStartPageActive() {
        return this.$route.params.entityId === 'start';
    }
    private get isProductDetailsRoute() {
        return this.$route.params.entityName === CmsEntityTypes.ProductDetails;
    }
    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }
    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }
    private get temporaryCmsUserData() {
        return this.$store.getters['temporaryCmsData/cmsUser'];
    }
    private get isCmsUserSuperAdmin() {
        if (this.temporaryCmsUserData == null) {
            return false;
        }
        return this.temporaryCmsUserData.roles.includes('ROLE_SUPER_ADMIN');
    }
    private get shouldDeleteButtonShow() {
        if (this.temporaryCmsUserData == null || !this.canEditUserRights) {
            return false;
        }
        return this.temporaryCmsUserData.userGroupConnections
            .map((userGroupConnection: UserGroupConnection) => {
                return userGroupConnection.key;
            })
            .includes(this.$route.params.userGroupConnectionKey);
    }
    private get entity() {
        if (
            this.entityConfig == null ||
            this.entityId == null ||
            this.entityConfig.getEntityRepositoryReference == null
        ) {
            return null;
        }
        return this.entityConfig.getEntityRepositoryReference(this.entityId);
    }
    private get additionalEntity() {
        if (
            this.entityConfig == null ||
            this.additionalEntityId == null ||
            this.entityConfig.getAdditionalEntityRepositoryReference == null
        ) {
            return null;
        }
        return this.entityConfig.getAdditionalEntityRepositoryReference(this.additionalEntityId);
    }
    private get title() {
        switch (this.$route.params.entityName) {
            case CmsEntityTypes.ProductDetails:
                if (this.additionalEntity !== null) {
                    return this.additionalEntity.name;
                }
                break;
            case CmsEntityTypes.ChecklistFields:
            case CmsEntityTypes.Labels:
                if (this.entity !== null) {
                    return this.entity.name;
                }
                break;
            default:
                return '';
        }
    }
    private get productCategories(): any {
        return ProductCategoriesRepository.getAll();
    }
    private async getProperEntity() {
        this.prepareStateForEditingOrCreating(this.$route.params.entityId);
        this.additionalEntityId = this.$route.query.additionalId as string;
        this.entityConfig = setEntityDefaultConfig(this.$route.params.entityName);
        if (this.entityConfig == null) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: `${this.$t('Traženi pojam ne postoji')} (${this.$route.params.entityName})`,
            });
            this.loadingOverlay.stop();
            this.$router.push({ name: RouteNames.home });
            return;
        }
        if (this.$route.params.entityName === CmsEntityTypes.Users) {
            await UserAlias.deleteAll();
        }

        if (this.isEditing) {
            await this.fetchProperEntity(this.entityConfig.entityFetchEndpoint, this.entityId, this.selectedLocale);
        }
        if (this.additionalEntityId) {
            await this.fetchProperEntity(
                this.entityConfig.additionalEntityFetchEndpoint,
                this.additionalEntityId,
                this.selectedLocale
            );
        }
        this.fetchDropdownOptions(this.entityConfig.entitySegments);
        this.setFormInitialValues(this.entity);
        if (this.$route.params.entityName === CmsEntityTypes.Users && this.temporaryCmsUserData !== null) {
            this.entityFormValues = this.temporaryCmsUserData;
        }
        if (
            this.$route.params.entityName === CmsEntityTypes.UserGroupConnections &&
            this.temporaryCmsUserData !== null
        ) {
            const temporaryUserGroupConnection = this.temporaryCmsUserData.userGroupConnections.find(
                (userGroupConnection: UserGroupConnection) => {
                    return userGroupConnection.key === this.$route.params.userGroupConnectionKey;
                }
            );
            if (temporaryUserGroupConnection == null) {
                return;
            }
            this.entityFormValues.userGroup = temporaryUserGroupConnection.userGroup;
            if (temporaryUserGroupConnection.userRights != null) {
                temporaryUserGroupConnection.userRights.forEach((userRight: string) => {
                    this.entityFormValues[userRight] = true;
                });
            }
        }
        if (this.entityConfig == null) {
            return;
        }
        this.entityConfig.entitySegments.forEach((segment: EntitySegment) => {
            if (segment.indeterminateGroup != null) {
                const checkedList = segment.editablePropertiesConfiguration.filter(
                    (editableProperty: EditableProperty) => this.entityFormValues[editableProperty.name]
                );
                const isIndeterminate =
                    !!checkedList.length && checkedList.length < segment.editablePropertiesConfiguration.length;
                const isCheckedAll = checkedList.length === segment.editablePropertiesConfiguration.length;
                this.$set(this.entityFormValues, `indeterminate-${segment.indeterminateGroup}`, isIndeterminate);
                this.$set(this.entityFormValues, `${segment.indeterminateGroup}-checkAll`, isCheckedAll);
            }
        });
    }

    private setLocalFormValuesObject() {
        if (this.$route.params.entityName === CmsEntityTypes.Users) {
            const localUGC = this.entityFormValues.userGroupConnections;

            if (!localUGC.length) {
                return;
            }

            this.initialFormValues = JSON.parse(JSON.stringify(localUGC));
        }
    }

    private prepareStateForEditingOrCreating(entityId: string) {
        if (!entityId) {
            return;
        }
        if (entityId === 'new' || entityId === 'start') {
            this.isEditing = false;
            this.entityId = null;
            return;
        }
        this.isEditing = true;
        this.entityId = entityId;
    }
    private async fetchDropdownOptions(entitySegments: EntitySegment[]) {
        for (const segment of entitySegments) {
            const editableDropdownProperties = segment.editablePropertiesConfiguration.filter(
                (editableProperty: EditableProperty) => editableProperty.type === 'dropdown'
            );
            for await (const editableProperty of editableDropdownProperties) {
                if (editableProperty.fetchOptionsEndpoint != null) {
                    await this.fetchProperEntity(editableProperty.fetchOptionsEndpoint);
                }
            }
        }
        if (this.$route.params.entityName === CmsEntityTypes.IntroScreenItem) {
            try {
                ProjectState.getAll();
                OrderStatus.getAll();
                Label.getAll();
            } catch (e) {
                return;
            }
        }
    }
    private async fetchProperEntity(
        entityFetchEndpoint: ((entityId?: any, locale?: string | null) => Promise<AxiosResponse | void>) | null,
        entityId?: string | null,
        locale?: string | null
    ) {
        this.loadingOverlay.start();
        if (entityFetchEndpoint == null) {
            return;
        }
        try {
            if (entityId) {
                await entityFetchEndpoint(entityId, locale);
            } else {
                await entityFetchEndpoint();
            }
        } catch (e) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: this.$t('Ovaj proizvod ne postoji') as string,
            });
            this.loadingOverlay.stop();
            this.$router.push({ name: RouteNames.home });
            return;
        }
        this.loadingOverlay.stop();
    }
    private setFormInitialValues(entity: any) {
        this.entityFormValues = {};
        if (this.entityConfig == null) {
            return;
        }
        this.entityConfig.entitySegments.forEach((segment: EntitySegment) => {
            segment.editablePropertiesConfiguration.map((editableProperty: EditableProperty) => {
                if (editableProperty.shouldCreateMultiples && editableProperty.numberOfItemsToBeShown) {
                    for (let i = 1; i <= editableProperty.numberOfItemsToBeShown; i++) {
                        this.$set(
                            this.entityFormValues,
                            `${editableProperty.name}${i}`,
                            getDefaultValue(editableProperty, entity, i)
                        );
                    }
                } else {
                    this.$set(
                        this.entityFormValues,
                        editableProperty.name,
                        getDefaultValue(editableProperty, entity, undefined)
                    );
                }
                if (editableProperty.name === 'userGroupConnections' && entity == null) {
                    const userGroup = this.currentUserGroup;
                    if (userGroup == null) {
                        return;
                    }
                    this.entityFormValues[editableProperty.name].push({
                        key: '0',
                        user: {
                            id: null,
                        },
                        userGroup: {
                            name: userGroup.name,
                            id: userGroup.id,
                        },
                        userRights: [],
                    });
                }
            });
            if (segment.indeterminateGroup != null) {
                const checkedList = segment.editablePropertiesConfiguration.filter(
                    (editableProperty: EditableProperty) => this.entityFormValues[editableProperty.name]
                );
                const isIndeterminate =
                    !!checkedList.length && checkedList.length < segment.editablePropertiesConfiguration.length;
                const isCheckedAll = checkedList.length === segment.editablePropertiesConfiguration.length;
                this.$set(this.entityFormValues, `indeterminate-${segment.indeterminateGroup}`, isIndeterminate);
                this.$set(this.entityFormValues, `${segment.indeterminateGroup}-checkAll`, isCheckedAll);
            }
        });
    }
    private onDeleteUserGroup() {
        this.$confirm({
            title: this.$t('Jeste li sigurni da želite obrisati korisnika iz ove korisničke grupe?'),
            content: '',
            okText: this.$t('Da') as string,
            okType: 'danger',
            cancelText: this.$t('Ne') as string,
            onOk: () => {
                this.deleteUserGroup();
            },
        });
    }
    private updateUnsavedDataState({ state }: { state: boolean }) {
        this.hasUnsavedData = state;
    }
    private async deleteUserGroup() {
        this.loadingOverlay.start();
        if (this.isEditing) {
            await UserGroupConnection.deleteExisting(this.$route.params.entityId);
        }
        this.$store.dispatch('temporaryCmsData/deleteUserGroupConnection', this.$route.params.userGroupConnectionKey);
        this.hasUnsavedData = false;
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.Users,
                entityId: this.$route.params.userId || 'new',
            },
        });
        this.loadingOverlay.stop();
    }
    private radioButtonChange(value: string) {
        this.productCategoryId = value;
    }
    private async onOpenProductSystem({ productSystemId }: any) {
        const productSystem = ProductSystemRepository.getById(productSystemId);
        if (productSystem == null) {
            return;
        }
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.ProductDetails,
                entityId: productSystem.productSystemDetails ? productSystem.productSystemDetails.id : 'new',
            },
            query: {
                additionalId: productSystem.id,
            },
        });
    }
    private async onOpenProduct(productId: string) {
        const product = ProductRepository.getById(productId);
        if (product == null) {
            return;
        }
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.ProductImage,
                entityId: product ? product.id : 'new',
            },
        });
    }
    private setInitialProductCategory() {
        if (this.$route.params.entityName === CmsEntityTypes.ProductImage && this.$route.params.entityId) {
            const product = ProductRepository.getById(this.$route.params.entityId);
            if (product) {
                this.productCategoryId = this.productCategories.find((c: any) => {
                    return c.products.find((p: any) => p.id === product.id) !== undefined;
                })?.id;
                return;
            }
        }
        this.productCategoryId = this.productCategories[0].id;
    }
    private async fetchAllRecords(entity: any) {
        await entity.getAll();
    }
    private scrollToIndexDetails() {
        (this.$refs.cmsIndexDetails as Vue).$el.scrollIntoView();
    }
    private async updateSalesProcesses() {
        // @ts-ignore
        await this.$refs.cmsIndexDetails.$refs.salesProcessForms[0].validateAndSubmitForms();
    }

    private beforeDestroy() {
        this.$store.dispatch('temporaryCmsData/updateCmsUser', null);
        EventBus.$off(EventBusEvents.changesInDataMade, this.updateUnsavedDataState);
    }
    @Watch('$route', { immediate: true, deep: true })
    private async onRouteChange() {
        if (
            this.$route.params.entityName === CmsEntityTypes.UserGroupConnections &&
            this.temporaryCmsUserData == null
        ) {
            this.$router.push({
                name: RouteNames.settings,
                params: {
                    entityName: CmsEntityTypes.Users,
                },
            });
        }
        await this.getProperEntity();
        if (this.isProductDetailsRoute && !this.isStartPageActive) {
            this.scrollToIndexDetails();
        }
    }

    @Watch('entityFormValues', { deep: true })
    private async onEntityFormValuesChange(newValue: any, oldValue: any) {
        if (Object.entries(oldValue).length <= 0) {
            return;
        }
        this.hasUnsavedData = true;
    }
    @Watch('selectedLocale')
    private async onSelectedLocaleChange() {
        if (this.selectedLocale == null || this.entityConfig == null) {
            return;
        }
        await ProductSystemDetailsConfiguration.deleteAll();
        await UploadedPdf.deleteAll();
        await this.getProperEntity();
        this.$notification.info({
            message: this.$t('Jezik za prijevod je promijenjen') as string,
            description: ``,
        });
    }
}
