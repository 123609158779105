import UserAlias from '@/models/UserAlias';

export default class UserAliasRepository {
    public static getAll() {
        return UserAlias.query().withAll().get();
    }

    public static getById(id: string | null) {
        if (id == null) {
            return null;
        }
        return UserAlias.query().whereId(id).withAll().first();
    }
}
