
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EntityConfiguration } from '@/interfaces/components/cms/EntityConfiguration';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { EntityInfo } from '@/interfaces/components/cms/EntityInfo';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { FieldTypes } from '@/enums/components/CmsIndex/FieldTypes';
import { updateFile } from '@/helpers/CmsIndexHelper';
import { JsonAPIError } from '@/interfaces/general/JsonAPIError';
import { mapTranslationByKey } from '@/helpers/TranslationHelper';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { EntitySegment } from '@/interfaces/components/cms/EntitySegment';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import { RouteNames } from '@/enums/routes/RouteNames';
import UserGroupConnection from '@/models/UserGroupConnection';
import CMSUser from '@/models/CMSUser';
import UserGroupRepository from '@/repositories/UserGroupRepository';
import CMSUserRepository from '@/repositories/CMSUserRepository';
import { UserRightsEnum } from '@/enums/global/UserRights';
import { getNumberRangeGroups, validateNumberRange } from '@/helpers/NumberRangeHelper';
import { AxiosResponse } from 'axios';
import { generatePromisesForUpdatingConfigurations, updateOrCreateConfiguratorItems } from '@/helpers/CmsSaveHelper';
import User from '@/models/User';
import { updateOrCreateUserAliases } from '@/helpers/CmsIndex/UserAliasHelper';
import { group } from '@actions/core';

@Component({
    name: 'CmsSaveIndexButton',
})
export default class CmsSaveIndexButton extends Vue {
    @Prop({ required: true }) private entityId!: string | null;
    @Prop({ required: true }) private entityForm!: EntityInfo;
    @Prop({ default: undefined }) private additionalEntityId!: string;
    @Prop({ required: true }) private isEditing!: boolean;
    @Prop({ required: true }) private isCmsUserSuperAdmin!: boolean;
    @Prop({ default: null }) private selectedLocale!: string | null;
    @Prop({ required: true }) private updateSalesProcesses!: () => {};
    @Prop({ required: true }) private entityConfig!: EntityConfiguration | null;
    @Prop({ required: true }) private initialFormValues!: any[];

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private initialGroupConnections: any = [];

    public get canEditUser() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER);
    }

    public get canEditUserRights() {
        return this.userRights.includes(UserRightsEnum.EDIT_USER_RIGHTS);
    }

    public get canCreateUser() {
        return this.userRights.includes(UserRightsEnum.CREATE_USER);
    }

    private get userRights() {
        return this.$store.getters['jwtData/userRights'];
    }

    private get currentUserGroup() {
        return UserGroupRepository.getById(String(this.$store.getters['jwtData/currentUserGroup']));
    }

    private get isUserElementDisabled() {
        if (
            this.$route.params.entityName !== CmsEntityTypes.Users &&
            this.$route.params.entityName !== CmsEntityTypes.UserGroupConnections
        ) {
            return false;
        }
        if (this.$route.params.entityName === CmsEntityTypes.UserGroupConnections) {
            return !this.canEditUserRights;
        }

        if (this.isEditing) {
            return !this.canEditUser;
        } else {
            return !this.canCreateUser;
        }
    }

    private get isWarrantyWarning() {
        return this.$route.params.entityName === 'warranty-warnings' && this.$route.params.entityId !== 'new';
    }

    private fieldTranslation(editablePropertyName: string) {
        return mapTranslationByKey(editablePropertyName);
    }

    private async onSaveOrUpdateProductDetails() {
        this.loadingOverlay.start();
        if (this.entityConfig == null) {
            return;
        }

        if (this.checkForRequiredFields(this.entityConfig.entitySegments, this.entityForm)) {
            this.loadingOverlay.stop();
            return;
        }

        try {
            await this.updateOrCreateIntroScreenItems();
        } catch (e) {
            this.loadingOverlay.stop();
            return;
        }

        if (this.$route.params.entityName === CmsEntityTypes.UserGroupConnections) {
            try {
                if (this.isEditing || this.$route.params.userGroupConnectionKey != null) {
                    await this.$store.dispatch('temporaryCmsData/updateUserGroupConnection', {
                        userGroupConnection: this.entityForm,
                        userGroupConnectionId: this.entityId,
                        userGroupConnectionKey: this.$route.params.userGroupConnectionKey,
                    });
                } else {
                    await this.$store.dispatch('temporaryCmsData/addUserGroupConnection', {
                        userGroupConnection: this.entityForm,
                        userGroupConnectionId: this.entityId,
                        userId: this.$route.params.userId,
                    });
                }
            } catch (e) {
                this.loadingOverlay.stop();
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: this.$t('Odabrana korisnička grupa već postoji za ovog korisnika') as string,
                });
                return;
            }

            this.emitDataChanges(false);

            this.$router.push({
                name: RouteNames.cmsIndex,
                params: {
                    entityName: CmsEntityTypes.Users,
                    entityId: this.$route.params.userId || 'new',
                },
            });
            this.loadingOverlay.stop();
            return;
        }

        if (
            this.$route.params.entityName === CmsEntityTypes.Users &&
            this.entityForm.userGroupConnections.length <= 0
        ) {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: this.$t('Korisnik mora imati barem jednu dodijeljenu korisničku grupu') as string,
            });

            this.loadingOverlay.stop();
            return;
        }

        let newEntityDetails = null;
        const numberRangeGroupArrays = getNumberRangeGroups(this.entityConfig.entitySegments, this.entityForm);
        if (numberRangeGroupArrays.length > 0) {
            for (const numberRangeGroupArray of numberRangeGroupArrays) {
                for await (const numberRangeGroup of numberRangeGroupArray) {
                    if (this.checkForNumberRangeErrors(numberRangeGroup)) {
                        this.loadingOverlay.stop();
                        return;
                    }

                    try {
                        if (numberRangeGroup.id != null && this.entityConfig.nestedEntityUpdateEndpoint != null) {
                            await this.entityConfig.nestedEntityUpdateEndpoint(numberRangeGroup.id, {
                                ...numberRangeGroup,
                                user: this.entityForm.user,
                            });
                        } else if (
                            numberRangeGroup.id == null &&
                            this.entityConfig.nestedEntityCreateEndpoint != null
                        ) {
                            const response = await this.entityConfig.nestedEntityCreateEndpoint({
                                ...numberRangeGroup,
                                user: this.entityForm.user,
                            });
                            numberRangeGroup.id = response.data.id;
                        }
                    } catch (e) {
                        const errorObj = e as any;

                        if (
                            errorObj.response &&
                            errorObj.response.status === 422 &&
                            errorObj.response.data &&
                            errorObj.response.data.errors.length > 0
                        ) {
                            switch (errorObj.response.data.errors[0].detail) {
                                case 'postal.code.conflict':
                                    this.$notification.error({
                                        message: this.$t('Dogodila se greška') as string,
                                        description: this.$t(
                                            'Neki od navedenih poštanskih brojeva se preklapaju s poštanskim brojevima drugog korisnika'
                                        ) as string,
                                    });
                                    break;
                                default:
                                    let error;

                                    if (e instanceof Error) {
                                        error = e.message;
                                    } else {
                                        error = (e as { response: { data: { meta: { message: string } } } }).response
                                            .data.meta.message;
                                    }

                                    this.$notification.error({
                                        message: this.$t('Dogodila se greška') as string,
                                        description: error,
                                    });
                                    break;
                            }
                        }
                        this.loadingOverlay.stop();
                        return;
                    }
                }
            }
        }
        const extendedFilesArray = this.getExtendedFilesArray(this.entityConfig.entitySegments, this.entityForm);
        if (extendedFilesArray.length > 0) {
            for (const extendedFiles of extendedFilesArray) {
                try {
                    await Promise.all(extendedFiles.map((file: any) => updateFile(file)));
                } catch (e) {
                    this.loadingOverlay.stop();
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: 'Files failed to upload',
                    });
                    return;
                }
            }
        }
        const configuratorArrays = this.getConfiguratorArrays(this.entityConfig.entitySegments, this.entityForm);

        if (configuratorArrays.length > 0) {
            for (const configuratorArray of configuratorArrays) {
                let requestsToBeCalled = [];

                try {
                    requestsToBeCalled = generatePromisesForUpdatingConfigurations({
                        configuratorArray,
                        entityConfig: this.entityConfig,
                        selectedLocale: this.selectedLocale,
                    });
                    await updateOrCreateConfiguratorItems(requestsToBeCalled, configuratorArray);
                } catch (e) {
                    this.loadingOverlay.stop();
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: (e as Error).message,
                    });
                    return;
                }
            }
        }

        try {
            // sometimes necessary since all updates are done on the nested property, so no need to update parent
            const shouldNotUpdateParent =
                this.$route.params.entityName === CmsEntityTypes.PostCodeConnections ||
                this.$route.params.entityName === CmsEntityTypes.IntroScreenItem;

            if (!shouldNotUpdateParent) {
                if (this.isEditing && this.entityId && this.entityConfig.entityUpdateEndpoint) {
                    await this.entityConfig.entityUpdateEndpoint(
                        this.entityId,
                        this.entityForm,
                        this.additionalEntityId,
                        this.selectedLocale
                    );
                } else if (this.entityConfig.entityCreateEndpoint) {
                    newEntityDetails = await this.entityConfig.entityCreateEndpoint(
                        this.entityForm,
                        this.additionalEntityId,
                        this.selectedLocale
                    );
                }
            }
        } catch (e) {
            this.loadingOverlay.stop();
            const errors: Array<{ source: string; message: string }> = [];
            const errorObj = e as any;

            if (errorObj.response && errorObj.response.data && errorObj.response.data.errors) {
                errorObj.response.data.errors.forEach((error: JsonAPIError) => {
                    let notificationTitle = this.$t('Dogodila se greška');
                    if (error.source) {
                        const source = error.source.pointer.split('/')[3];
                        notificationTitle = this.fieldTranslation(source);
                        errors.push({
                            source,
                            message: error.detail,
                        });
                    }
                    window.setTimeout(() => {
                        this.$notification.error({
                            message: notificationTitle as string,
                            description: error.detail ? error.detail : (error.title as string),
                        });
                    }, 50);
                });
                EventBus.$emit(EventBusEvents.cmsError, errors);
                return;
            }
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }

        const parentEntityId = newEntityDetails ? newEntityDetails.data.id : this.entityId;

        try {
            await this.updateOrCreateUserGroupConnections(
                this.entityConfig.entitySegments,
                this.entityForm,
                newEntityDetails
            );

            await this.updateOrCreateOfferPdfProperties(newEntityDetails);
            await updateOrCreateUserAliases(this.entityForm.aliases, parentEntityId, this.$route.params.entityName);
        } catch (e) {
            this.loadingOverlay.stop();
            this.$notification.error({
                message: this.$t('Dogodila se greška,') as string,
                description: (e as Error).message,
            });
            return;
        }

        if (this.$route.params.entityName === CmsEntityTypes.IntroScreenItem) {
            this.notifyAndRedirectToIntroScreen();
            return;
        }

        if (this.$route.params.entityName === CmsEntityTypes.ProductDetails) {
            const productPickerElement = document.querySelector('.c-cms-index__product-picker');

            if (productPickerElement == null) {
                return;
            }
            this.$router.push({
                name: RouteNames.cmsIndex,
                params: {
                    entityName: CmsEntityTypes.ProductDetails,
                    entityId: 'start',
                },
            });
            productPickerElement.scrollIntoView();
            this.$notification.success({
                message: `${this.$t('Promjene uspješne!')}`,
                description: '',
            });
            EventBus.$emit(EventBusEvents.deselectSelectedProductSystem);
            this.loadingOverlay.stop();
            return;
        }

        this.emitDataChanges(false);
        if (this.$route.params.entityName === CmsEntityTypes.Users) {
            this.$router.push({ name: RouteNames.cmsUsers });
        } else {
            this.$router.go(-1);
        }
        this.loadingOverlay.stop();
        this.$notification.success({
            message: `${this.$t('Promjene uspješne!')}`,
            description: '',
        });
    }

    private async updateOrCreateOfferPdfProperties(newEntityDetails: null | AxiosResponse) {
        if (
            (this.$route.params.entityName !== CmsEntityTypes.PdfOfferOptions &&
                this.$route.params.entityName !== CmsEntityTypes.PdfOrderTemplatesOptions) ||
            this.entityConfig == null
        ) {
            return Promise.resolve();
        }

        const parentEntityId = newEntityDetails ? newEntityDetails.data.id : this.entityId;

        try {
            if (this.additionalEntityId && this.entityConfig.nestedEntityUpdateEndpoint != null) {
                await this.entityConfig.nestedEntityUpdateEndpoint(
                    this.additionalEntityId,
                    this.entityForm,
                    parentEntityId
                );
            } else if (this.entityConfig.nestedEntityCreateEndpoint != null) {
                await this.entityConfig.nestedEntityCreateEndpoint(this.entityForm, parentEntityId);
            }
        } catch (error) {
            throw error;
        }

        return Promise.resolve();
    }

    private getExtendedFilesArray(entitySegments: EntitySegment[], entityInfo: any) {
        const editableProperties = entitySegments
            .map((segment: EntitySegment) => segment.editablePropertiesConfiguration)
            .flat();

        const extendedFilesEditableProperties = editableProperties.filter((editableProperty: EditableProperty) => {
            return editableProperty.type === FieldTypes.ExtendedFiles;
        });
        return extendedFilesEditableProperties.map((editableProperty: EditableProperty) => {
            return entityInfo[editableProperty.name];
        });
    }

    private getConfiguratorArrays(entitySegments: EntitySegment[], entityInfo: any) {
        const editableProperties = entitySegments
            .map((segment: EntitySegment) => segment.editablePropertiesConfiguration)
            .flat();

        const extendedFilesEditableProperties = editableProperties.filter((editableProperty: EditableProperty) => {
            return editableProperty.type === FieldTypes.Configurator;
        });
        return extendedFilesEditableProperties.map((editableProperty: EditableProperty) => {
            return entityInfo[editableProperty.name];
        });
    }

    private checkForNumberRangeErrors(numberRangeGroup?: any) {
        switch (this.$route.params.entityName) {
            case CmsEntityTypes.PostCodeConnections:
                if (numberRangeGroup == null) {
                    return true;
                }

                const postCodeValidationResult = validateNumberRange(numberRangeGroup);

                if (postCodeValidationResult.hasErrors) {
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: postCodeValidationResult.message,
                    });
                    return true;
                }
                break;
        }
        return false;
    }

    private checkForRequiredFields(entitySegments: EntitySegment[], entityInfo: any) {
        const editableProperties = entitySegments
            .map((segment: EntitySegment) => segment.editablePropertiesConfiguration)
            .flat();
        let isMissingFields = false;

        editableProperties.forEach((editableProperty: EditableProperty) => {
            if (
                editableProperty.isRequired &&
                (entityInfo[editableProperty.name] == null || entityInfo[editableProperty.name] === '')
            ) {
                this.$notification.error({
                    message: `${this.$t('Polje')} ${this.$t(editableProperty.label)} ${this.$t('je obavezno')}`,
                    description: '',
                });
                isMissingFields = true;
            }
        });

        return isMissingFields;
    }

    private validateUserRights(userRights: string[]) {
        if (userRights == null) {
            return [];
        }

        let updatedUserRights = userRights;
        if (!this.isCmsUserSuperAdmin) {
            updatedUserRights = updatedUserRights.filter(
                (userRight: string) => userRight !== (UserRightsEnum.CMS_CURRENCIES as string)
            );
        }

        return updatedUserRights;
    }

    private async updateOrCreateUserGroupConnections(
        entitySegments: EntitySegment[],
        entityInfo: EntityInfo,
        createdUser: any
    ) {
        const editableProperties = entitySegments
            .map((segment: EntitySegment) => segment.editablePropertiesConfiguration)
            .flat();
        const userGroupEditableProperty = editableProperties.find((editableProperty: EditableProperty) => {
            return editableProperty.type === 'userGroups';
        });

        if (userGroupEditableProperty === undefined || this.currentUserGroup == null) {
            return Promise.resolve();
        }

        // @ts-ignore
        const userGroupConnections = entityInfo[userGroupEditableProperty.name];

        for await (const userGroupConnection of userGroupConnections) {
            const hasGroupConnectionChanged = this.checkIfGroupConnectionWasChanged(userGroupConnection);

            if (!hasGroupConnectionChanged) {
                continue;
            }

            userGroupConnection.user.id = this.entityId || createdUser.data.id;
            const refetchedUser = await CMSUser.getById(userGroupConnection.user.id);
            const user = CMSUserRepository.getById(userGroupConnection.user.id);

            if (user == null) {
                return Promise.reject();
            }

            userGroupConnection.userRights = this.validateUserRights(userGroupConnection.userRights);

            const existingUserGroupIds = user.userGroupConnections.map(
                (userGroupConnectionSingle: UserGroupConnection) => {
                    return userGroupConnectionSingle.userGroup.id;
                }
            );
            if (userGroupConnection.id == null && !existingUserGroupIds.includes(userGroupConnection.userGroup.id)) {
                try {
                    await UserGroupConnection.createNew(userGroupConnection);
                } catch (e) {
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: (e as Error).message,
                    });
                    this.loadingOverlay.stop();
                    return Promise.reject();
                }
            } else {
                let updatedId = userGroupConnection.id;
                if (updatedId == null) {
                    updatedId = refetchedUser?.userGroupConnection_ids?.[0] ?? refetchedUser?.userGroupConnections?.[0];
                }
                try {
                    await UserGroupConnection.updateExisting(updatedId, userGroupConnection);
                } catch (e) {
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: (e as Error).message,
                    });
                    this.loadingOverlay.stop();
                    return Promise.reject();
                }
            }
        }

        await User.runInitialActionsAfterRouteChange();

        return Promise.resolve();
    }

    private checkIfGroupConnectionWasChanged(currentUserGroup: any) {
        const groupConnection = this.initialFormValues.find((group) => {
            return currentUserGroup.id === group.id;
        });

        if (!groupConnection) {
            return true;
        }

        if (groupConnection?.userRights?.length !== currentUserGroup?.userRights?.length) {
            return true;
        }

        const sortedInitialUR = groupConnection.userRights.slice().sort();
        const sortedCurrentUR = currentUserGroup.userRights.slice().sort();

        const areArraysEqual = sortedInitialUR.every(
            (userRight: string, index: number) => userRight === sortedCurrentUR[index]
        );

        if (!areArraysEqual) {
            return true;
        }

        return false;
    }

    private async updateOrCreateIntroScreenItems() {
        if (this.$route.params.entityName === CmsEntityTypes.IntroScreenItem) {
            try {
                await this.updateSalesProcesses();
            } catch (e) {
                return Promise.reject(e);
            }
            return Promise.resolve();
        }
    }

    private notifyAndRedirectToIntroScreen() {
        this.$notification.success({
            message: `${this.$t('Promjene uspješne!')}`,
            description: '',
        });
        this.emitDataChanges(false);
        this.loadingOverlay.stop();
        this.$router.push({
            name: RouteNames.statisticsDashboard,
        });
        return;
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }
}
