import ProductSystemDetailsConfiguration from '@/models/ProductSystemDetailsConfiguration';
import i18n from '@/i18n';
import { AxiosResponse } from 'axios';

export function generatePromisesForUpdatingConfigurations(
    {
        configuratorArray,
        entityConfig,
        selectedLocale,
    }: {
        configuratorArray: ProductSystemDetailsConfiguration[],
        entityConfig: any,
        selectedLocale: string | null,
    }) {
    const requestsToBeCalled = [];
    for (const configuratorItem of configuratorArray) {
        if (!configuratorItemHasTitleAndImage(configuratorItem)) {
            throw new Error(i18n.t('Provjerite da sve konfiguracije sadrže sliku i naziv') as string);
        }

        if (!entityConfigContainsUpdateAndCreateEndpoints(entityConfig)) {
            throw new Error('Update or create entity endpoints are missing');
        }

        if (configuratorItem.id != null) {
            requestsToBeCalled.push(entityConfig.nestedEntityUpdateEndpoint(
                configuratorItem.id,
                configuratorItem,
                undefined,
                selectedLocale));
        } else {
            requestsToBeCalled.push(entityConfig.nestedEntityCreateEndpoint(
                configuratorItem,
                undefined,
                selectedLocale));
        }
    }

    return requestsToBeCalled;
}

function entityConfigContainsUpdateAndCreateEndpoints(entityConfig: any) {
    return entityConfig.nestedEntityUpdateEndpoint != null &&
        entityConfig.nestedEntityCreateEndpoint != null;
}


function configuratorItemHasTitleAndImage(configuratorItem: ProductSystemDetailsConfiguration) {
    return (configuratorItem.title !== null) &&
        configuratorItem.image != null;
}

export async function updateOrCreateConfiguratorItems(
    requestsToBeCalled: Array<Promise<AxiosResponse>>,
    configuratorArray: ProductSystemDetailsConfiguration[]) {
    await Promise.all(requestsToBeCalled).then((responses) => {
        for (const [index, configuratorItem] of configuratorArray.entries()) {
            if (configuratorItem.id == null) {
                configuratorItem.id = responses[index].data.id;
            }
        }
        return Promise.resolve();
    }).catch((e) => {
        return Promise.reject(e);
    });
}
