var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Form',{staticClass:"wrapperAddressForm",attrs:{"colon":false,"form":_vm.form},on:{"submit":_vm.validateAndSubmit}},[_c('div',{staticClass:"row"},[_c('FormItem',{attrs:{"label":_vm.$t('Dr\u017eava *')}},[_c('Select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'country',
                    {
                        initialValue: _vm.userAlias && _vm.userAlias.country ? _vm.userAlias.country.id : null,
                        rules: [{ required: true, message: 'Država je obavezna!' }],
                    },
                ]),expression:"[\n                    'country',\n                    {\n                        initialValue: userAlias && userAlias.country ? userAlias.country.id : null,\n                        rules: [{ required: true, message: 'Država je obavezna!' }],\n                    },\n                ]"}],attrs:{"placeholder":_vm.$t('Odaberi dr\u017eavu'),"data-test":"country","filterOption":"","optionFilterProp":"children","show-search":""}},_vm._l((_vm.countries),function(country){return _c('SelectOption',{key:country.id,staticClass:"u-b1",attrs:{"data-test":country.name,"value":country.id}},[_vm._v(" "+_vm._s(country.name)+" ")])}),1)],1)],1),_c('div',{staticClass:"row"},[_c('FormItem',{attrs:{"label":_vm.$t('Email')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                        initialValue: _vm.userAlias ? _vm.userAlias.email : '',
                        rules: [
                            { type: 'email', required: false, message: _vm.$t('Navedite ispravanu e-mail adresu!') },
                        ],
                    },
                ]),expression:"[\n                    'email',\n                    {\n                        initialValue: userAlias ? userAlias.email : '',\n                        rules: [\n                            { type: 'email', required: false, message: $t('Navedite ispravanu e-mail adresu!') },\n                        ],\n                    },\n                ]"}],attrs:{"data-test":"email"}})],1),_c('FormItem',{attrs:{"label":_vm.$t('Telefon')}},[_c('Input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phoneNumber',
                    {
                        initialValue: _vm.userAlias ? _vm.userAlias.phoneNumber : '',
                    },
                ]),expression:"[\n                    'phoneNumber',\n                    {\n                        initialValue: userAlias ? userAlias.phoneNumber : '',\n                    },\n                ]"}],attrs:{"data-test":"phoneNumber"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }