
import Hero1Icon from '@/components/icons/Hero1Icon.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Product from '@/models/Product';
import ProductSystem from '@/models/ProductSystem';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProductRepository } from '@/repositories/ProductRepository';
import { MultipositionPopupSettings } from '@/interfaces/components/configurator/MultipositionPopupSettings';
import TopLevelProductSelector from '@/components/global/category-selector/TopLevelProductSelector.vue';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';

@Component({
    name: 'ProductAndSystemSelector',
    components: {TopLevelProductSelector, Hero1Icon, ProductAndSystemSelector},
})

export default class ProductAndSystemSelector extends Vue {
    @Prop({required: true}) private shouldPickerBeLocked!: boolean;
    @Prop({required: true}) private detailedSystemView!: boolean;
    @Prop({required: true}) private productsByCategoryId!: Product[];
    @Prop({required: true}) private shouldHaveThreeLevelsOfNavigation!: boolean;
    @Prop({required: true}) private productCategoryId!: string;

    private selectedProductId: string | null = null;
    private selectedProductSystemId: string | null = null;
    private isMultiplierModalVisible = false;
    private timesToMultiply = 1;

    private functionToCall: null | (() => void) = null;

    private get selectedProductsSystems() {
        if (this.selectedProduct == null) {
            return [];
        }
        return this.selectedProduct.productSystems.filter((productSystem) => !productSystem.isArchived);
    }

    private get selectedProduct() {
        if (this.selectedProductId == null) {
            return null;
        }
        return ProductRepository.getById(this.selectedProductId);
    }

    private getProductSystemonProductClicksIcon(productSystem: ProductSystem) {
        if (productSystem.productSystemDetails == null || productSystem.productSystemDetails.icon == null) {
            return null;
        }

        return `url(${productSystem.productSystemDetails.icon.image})`;
    }

    private onProductClick(settings: MultipositionPopupSettings) {
        this.selectedProductSystemId = settings.productSystemId ? settings.productSystemId : null;
        this.$emit('onProductClick', settings);
    }

    private getProductSystemsIcon(productSystem: ProductSystem) {
        if (productSystem.productSystemDetails == null || productSystem.productSystemDetails.icon == null) {
            return null;
        }

        return `url(${productSystem.productSystemDetails.icon.image})`;
    }

    private partialOpenMultipositionPopup(settings: MultipositionPopupSettings) {
        return () => {
            this.openMultipositionPopup.apply(this, [settings]);
        };
    }

    private closeMultiplierPopup() {
        this.isMultiplierModalVisible = false;
    }

    private openMultiplierPopup(settings: MultipositionPopupSettings) {
        this.isMultiplierModalVisible = true;
        this.functionToCall = this.partialOpenMultipositionPopup(settings);
    }

    private openMultipositionPopup(settings: MultipositionPopupSettings) {
        EventBus.$emit(EventBusEvents.emitOpenPopup, {...settings, timesToMultiply: this.timesToMultiply});
        this.timesToMultiply = 1;
        this.functionToCall = null;
    }

    private resetProductSystem() {
        this.selectedProductSystemId = null;
    }

    private created() {
        EventBus.$on(EventBusEvents.deselectSelectedProductSystem, this.resetProductSystem);
    }

    private async beforeCreate() {
        
        if(this.$route.params.entityName === CmsEntityTypes.ProductImage) {
            await Product.getById(this.$route.params.entityId); // because repo is sometimes empty
            this.selectedProductId = this.$route.params.entityId;
        }
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.deselectSelectedProductSystem, this.resetProductSystem);
    }

    @Watch('productCategoryId')
    private onProductCategoryIdChange() {
        if(this.$route.params.entityName !== CmsEntityTypes.ProductImage) {
            this.selectedProductId = null;
        }
        this.selectedProductSystemId = null;
    }
    
    private onSelectedProductFormChange(id: string) {
        this.$emit('onSelectedProductChange', id); // this.selectedProductId
    }
}
