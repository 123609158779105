var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-product-selector"},[_c('TopLevelProductSelector',{attrs:{"should-picker-be-locked":_vm.shouldPickerBeLocked,"shouldHaveThreeLevelsOfNavigation":_vm.shouldHaveThreeLevelsOfNavigation,"productCategoryId":_vm.productCategoryId,"products-by-category-id":_vm.productsByCategoryId,"selectedProductId":_vm.selectedProductId,"detailedSystemView":_vm.detailedSystemView},on:{"onProductClick":_vm.onProductClick,"openMultiplierPopup":_vm.openMultiplierPopup,"update:selectedProductId":function($event){_vm.selectedProductId=$event},"update:selected-product-id":function($event){_vm.selectedProductId=$event},"selectedProductIdChanged":_vm.onSelectedProductFormChange}}),(_vm.shouldHaveThreeLevelsOfNavigation && _vm.selectedProductsSystems.length > 0)?_c('div',{staticClass:"c-product-selector__product-systems"},_vm._l((_vm.selectedProductsSystems),function(productSystem){return _c('div',{key:productSystem.id,staticClass:"c-product-selector__product-system",class:{'is-selected': _vm.selectedProductSystemId === productSystem.id},attrs:{"data-test":`productSystem-${productSystem.name}`},on:{"click":() => {
                _vm.onProductClick({
                    productSystemId: productSystem.id,
                    productSystemName: productSystem.name,
                })}}},[_c('div',{staticClass:"c-product-selector__product-system-icon",style:({backgroundImage: _vm.getProductSystemsIcon(productSystem)})}),_c('span',{staticClass:"u-b2--"},[_vm._v(" "+_vm._s(productSystem.name)+" here ")])])}),0):_vm._e(),_c('AModal',{attrs:{"visible":_vm.isMultiplierModalVisible,"title":_vm.$t('Dodavanje proizvoda'),"wrapClassName":"multiply-confirm","cancel-text":_vm.$t('Odustani'),"ok-text":_vm.$t('Dodaj')},on:{"ok":() => {
                if (_vm.functionToCall != null) {
                    _vm.functionToCall();
                }
                _vm.closeMultiplierPopup();
            },"cancel":_vm.closeMultiplierPopup}},[_c('p',[_vm._v(_vm._s(_vm.$t('Upišite koliko puta želite dodati ovaj proizvod')))]),_c('AInputNumber',{staticStyle:{"width":"100%"},attrs:{"data-test":"times-to-multiply","min":1,"max":50},model:{value:(_vm.timesToMultiply),callback:function ($$v) {_vm.timesToMultiply=$$v},expression:"timesToMultiply"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }